<template>
  <van-popup v-model="visible" position="bottom" :z-index="2020" :close-on-click-overlay="false" :lock-scroll="false" >
    <div class="jpMore">
      <van-icon name="cross" size="20" color="#999" @click="()=>{this.$emit('onClose')}" class="close"/>
      <div class="line"></div>
      <div class="t_header">
        <div class="t">
          <label>盒内全部商品</label>
          <van-tag type="primary" color="#303031">随机获得以下任意一件商品</van-tag>
        </div>
        <div class="t2">盲盒内商品类型概率</div>
      </div>
      <van-row type="flex" class="ratlist">
        <van-col span="6" class="rat rat_1">67.489%</van-col>
        <van-col span="6" class="rat rat_2">32.500%</van-col>
        <van-col span="6" class="rat rat_3">0.010%</van-col>
        <van-col span="6" class="rat rat_4">0.001%</van-col>
      </van-row>
      <div class="list">
        <van-row type="flex" align="center" justify="space-between" class="g_li" v-for="(item,key) in goodList" :key="key">
          <van-col class="g_img"><img :src="item.image"/></van-col>
          <van-col class="g_info">
            <p class="name">
              <van-tag type="primary" :color="glabelsColor[item.tag]" text-color="#fff" size="small">{{glabels[item.tag]}}</van-tag>
              {{item.goods_name}}
            </p>
            <p class="price">¥<span>{{item.coin_price}}</span></p>
          </van-col>
        </van-row>
      </div>
    </div>

  </van-popup>
</template>
<script>
  export default {
    props: {
      goodList:Array,
      visible:Boolean,
    },
    data () {
      return {
        glabels:{
          legend:'传说',
          supreme:'史诗',
          rare:'稀有',
          normal:'普通',
        },
        glabelsColor:{
          legend:'#aa76ff',
          supreme:'#fe514f',
          rare:'#fab230',
          normal:'#5eaeff',
        },
      }
    },
    created(){

    },
    computed:{
    },
    watch:{
    },
    mounted(){

    },
    methods:{

    }
  }
</script>
<style lang="less" scoped>
  .jpMore{padding-bottom:10px;position:relative;
    .close{position:absolute;right:12px;top:24px;z-index:1;}
    .line{background-image:linear-gradient(-90deg,#ead020,#fd8a71,#bc459a);height:10px;}
    .t_header{padding:14px 10px;
      .t{font-size:16px;font-weight:700;height:22px;line-height:22px;
        label{background:url('@{static-img}i_gift.png') no-repeat right center;background-size:auto 100%;padding-right:24px;display:inline-block;}
        .van-tag{border-radius:0px 10px 10px 10px;font-weight:normal;margin-left:10px;padding:0px 8px;}
      }
      .t2{text-align:center;font-size:16px;margin-top:10px;font-weight:700;}
    }

    .ratlist{text-align:center;
      .rat{padding-top:40px;
        &.rat_1{background:url('@{static-img}rat1.png') no-repeat center top;background-size:auto 32px;}
        &.rat_2{background:url('@{static-img}rat2.png') no-repeat center top;background-size:auto 32px;}
        &.rat_3{background:url('@{static-img}rat3.png') no-repeat center top;background-size:auto 32px;}
        &.rat_4{background:url('@{static-img}rat4.png') no-repeat center top;background-size:auto 32px;}
      }

    }
    .list{height:50vh;overflow-y:auto;padding:0px 10px;margin-top:10px;
      .g_li{border-bottom:1px solid #f1f1f1;padding:10px 0px;
        .g_img{width:70px;
          img{width:100%;display:block;}
        }
        .g_info{width:calc(100% - 80px);
          .name{font-weight:700;height:24px;line-height:24px;overflow:hidden;white-space:nowrap;text-overflow:ellipsis;
            .van-tag{font-weight:normal;transform:scale(0.9);}

          }
          .price{color:#e12329;font-weight:700;margin-top:5px;
            span{font-size:16px;}
          }
        }
      }
    }

  }
</style>
