<template>
  <div class="container">
    <div class="flexRight">
      <p><a class="link" @click="()=>{
            contentHtml = detail.rule
            contentHtmlModal = true
          }">规则</a></p>
      <p><router-link to="/service" class="link">客服</router-link></p>
    </div>

    <div class="header">
      <img :src="oss+'box2_head.png'" width="100%"/>
      <van-count-down :time="time">
        <template #default="timeData">
          <span class="block">{{ timeData.hours }}</span>
          <span class="colon">:</span>
          <span class="block">{{ timeData.minutes }}</span>
          <span class="colon">:</span>
          <span class="block">{{ timeData.seconds }}</span>
        </template>
      </van-count-down>
    </div>

    <div class="logo"><img :src="oss+'logo.png'"/>享开盒子</div>

    <div class="text">
      <van-icon name="good-job-o" size="20" />
      超火爆，该最高抽奖品10000+人想要
    </div>

    <div class="detail">
      <div class="ginfo">
        <div class="gimg"><img :src="detail.rewardPrize"/></div>
        <div class="info">
          <div class="title">{{detail.prizeGoodsName}}</div>
          <div class="desc">有机会抽</div>
          <div class="tag">
            <van-tag v-for="(item,key) in labeList" :key="key" plain type="primary" color="#ed5f32" text-color="#ed5f32" size="small">{{item}}</van-tag>
          </div>
          <div class="other">
            <div class="price">¥<span>{{detail.price}}</span><label>开盒价</label></div>
            <div class="old_price">¥10998市场价</div>

          </div>
        </div>
      </div>

      <div class="group_info">
        <div class="item font-color-888">
          <div class="label"><van-icon name="gold-coin-o" size="16" /> 市场价：</div>
          <div class="value">¥10998</div>
        </div>
        <div class="item">
          <div class="label"><van-icon name="thumb-circle-o" size="16"/> 奖励开盒价：</div>
          <div class="value font-red">¥{{detail.price}}</div>
        </div>
        <div class="item">
          <div class="label"><van-icon name="fire-o" size="16"/> 有机会到手：</div>
          <div class="value font-red">{{detail.prizeGoodsName}}</div>
        </div>
      </div>

      <div class="phone">
        <van-field v-model="json.phone" type="tel" :border="false" ref="phone" placeholder="请输入您的手机号码">
          <template #left-icon>
            <van-icon class-prefix="icon" name="mobile" size="20" color="#aaa"/>
          </template>
        </van-field>
      </div>

      <div class="paybtn"><van-button round block native-type="submit" :loading="loading" color="#fd3e32" size="large" @click="payorder">立即领取</van-button></div>

      <div class="isread">
        <van-checkbox v-model="isread" icon-size="16px">
          提交即您已阅读并同意
          <a @click.stop="()=>{
            contentHtml = detail.privacy_policy
            contentHtmlModal = true
          }" class="font-color-blue">隐私协议</a>和<a @click.stop="()=>{
            contentHtml = detail.user_agreement
            contentHtmlModal = true
          }" class="font-color-blue">用户协议</a>
        </van-checkbox>
      </div>

      <div class="payList">
        <van-radio-group v-model="json.pay_way">
          <van-cell-group>
            <van-cell v-for="item in payList" :key="item.key">
              <template #icon><img :src="item.img" height="24"/></template>
              <template #title>
                <van-row type="flex" align="center" :gutter="2">
                  <van-col>{{item.name}}</van-col>
                  <van-col><van-tag plain type="primary" color="#ec4d36" text-color="#ec4d36">首单随机立减，最高至免单</van-tag></van-col>
                </van-row>
              </template>
              <template #right-icon>
                <van-radio :name="item.payWay" checked-color="#69c771"></van-radio>
              </template>
            </van-cell>
          </van-cell-group>
        </van-radio-group>
      </div>

      <div class="content">
        <div class="lm_title">— 最高抽奖品介绍 —</div>
        <div>
          <img :src="oss+'box2_content_1.jpg'" width="100%"/>
          <!--<img :src="oss+'box2_content_2.jpg'" width="100%"/>
          <img :src="oss+'box2_content_3.jpg'" width="100%"/>
          <img :src="oss+'box2_content_4.jpg'" width="100%"/>-->
        </div>
      </div>
      <div class="prize">
        <div class="lm_title">营业执照及奖品概率：</div>

        <div class="glist">
          <van-row type="flex">
            <van-col class="qualifications" @click="onImagePreview([oss+'qualifications.jpg'])">
              <img :src="oss+'qualifications.jpg'"/>
            </van-col>
            <template v-for="(item,key) in goodList">
              <van-col v-if="key<4" :key="item.id">
                <img :src="item.image" width="100%"/>
              </van-col>
            </template>
            <van-col class="more"><a @click="()=>{this.jpModal = true}">查看详情</a></van-col>
          </van-row>
        </div>
      </div>

      <div class="sale"><img :src="oss+'box2_salc.png'" width="100%"/></div>

      <foot/>
    </div>

    <van-popup v-if="contentHtmlModal" v-model="contentHtmlModal" :overlay-style="{backgroundColor:'rgba(0,0,0,.5)'}" :close-on-click-overlay="false" class="contentPop">
      <div class="html" v-html="contentHtml"></div>
      <div class="close"><van-button type="primary" round color="#2d8cf0" style="width:60%" @click="()=>{contentHtmlModal = false}">确定</van-button></div>
    </van-popup>

    <jp :visible="jpModal" :goodList="goodList" @onClose="()=>{jpModal = false}" />

  </div>
</template>
<script>
  import { ImagePreview } from "vant"
  import {getordernum} from '@/utils/utils'
  import foot from '@/components/footer'
  import jp from './components/jp'
  import {GetPayConfig,GetBoxInfo,CreateOrder,GetOrderStatus} from './api/api'
  export default {
    name:'box2',
    components: {foot,jp},
    data () {
      return {
        type:'taw',
        sessionKey:'box2_order',
        time:15 * 60 * 60 * 1000,
        labeList:['盲盒','正品官方','4G双卡'],
        glabels:{
          legend:'传说',
          supreme:'史诗',
          rare:'稀有',
          normal:'普通',
        },
        glabelsColor:{
          legend:'#aa76ff',
          supreme:'#fe514f',
          rare:'#fab230',
          normal:'#5eaeff',
        },
        payList:[],
        goodList:[],
        detail:{},
        json:{
          tag:'taw',
          pay_way:0,
          order_sn:'',
          phone:'',
          url:location.href,
          box_id:''
        },
        a_oId:'',
        paysuccess:0,
        paytime:3 * 1000,
        count:0,
        maxcount:10,
        timer:null,
        contentHtmlModal:false,
        contentHtml:'',
        loading:false,
        jpModal:false,
        isread:false,
        isback:false
      }
    },
    created(){
      const {a_oId,phone} = this.$route.query
      this.a_oId=a_oId==undefined?undefined:a_oId
      this.json={
        ...this.json,
        ...this.$route.query,
        phone:(phone==undefined?'':atob(phone))
      }
    },
    mounted(){
      window.addEventListener(
        "pageshow", ()=> {
          if(this.isback){
            location.reload()
            return false
          }
        },
        false
      )
      setTimeout(()=>{
        if(sessionStorage.getItem(this.sessionKey) && this.json.order_sn == ''){
          let order_info = JSON.parse(sessionStorage.getItem(this.sessionKey))
          this.json={
            ...this.json,
            ...order_info
          }
        }
        if(this.json.order_sn != ''){
          this.isread = true
          this.findOrderStatus()
        }
      },1000)
      this.findpayConfig()
    },
    computed:{
    },
    watch:{
      'json.phone':{
        handler:function(value){
          if(value.length == 11){
            if(!/^1[3456789]\d{9}$/.test(value)) {
              this.$toast('请输入正确的手机号码')
              this.$refs.phone.focus()
            }else{
              this.isread = true
              this.$refs.phone.blur()
            }
          }
        },
        deep:true
      }
    },
    methods:{
      findpayConfig(){
        GetPayConfig({type:this.json.tag}).then(result => {
          if(result.data.code==1){
            const {payConfig,box_id,commentList} = result.data.data
            this.payList=payConfig
            this.commentList = commentList
            this.json.pay_way=this.payList[0].payWay
            this.detail = result.data.data
            this.json.box_id=box_id

            this.findDetail(box_id)

            const {app_download_url,kefu_url} = this.detail
            sessionStorage.setItem('payConfig',JSON.stringify({app_download_url,kefu_url}))
          }else{
            this.$toast.fail(result.data.msg)
          }
        })

      },
      findDetail(box_id){
        GetBoxInfo({id:box_id}).then(result => {
          if(result.data.code==1){
            const {data} = result.data
            this.goodList=data
          }else{
            this.$toast.fail(result.data.msg)
          }
        })

      },
      payorder(){
        if(!this.isread){
          this.$toast('请阅读隐私协议与用户协议')
          return false
        }
        if(this.json.phone==''){
          this.$toast('请输入您的开盒手机号')
          this.$refs.phone.focus()
          return false
        }else if(!/^1[3456789]\d{9}$/.test(this.json.phone)){
          this.$toast('请输入正确的手机号码')
          this.$refs.phone.focus()
          return false
        }
        this.$toast.loading({
          message: '正在提交订单...',
          forbidClick: true,
        })
        let pay=this.payList.find(item=>item.payWay == this.json.pay_way)
        const {pwyAccount} = pay
        let order_sn = this.json.order_sn==''?getordernum(6):this.json.order_sn
        this.json={
          ...this.json,
          account:pwyAccount,
          order_sn:order_sn
        }
        this.getCreateOrder(this.json)
      },
      getCreateOrder(res){
        CreateOrder(res).then(result => {
          if(result.data.code==1){
            sessionStorage.setItem(this.sessionKey,JSON.stringify(res))
            setTimeout(()=>{
              this.findOrderStatus()
            },3000)
            this.jumpUrl(result.data.data)
          }else{
            this.$toast.fail(result.data.msg)
          }
        })
      },
      findOrderStatus(){
        GetOrderStatus({order_sn:this.json.order_sn}).then(result => {
          const {code} = result.data
          // let code = 1
          this.paysuccess = code
          if(code==1){
            this.$toast.success('支付成功')
            this.clearTime()
            setTimeout(()=>{
              this.finishPay()
            },500)
          }else{
            this.$dialog.confirm({
              title: '确认支付情况',
              confirmButtonText:'已完成支付',
              cancelButtonText:'重新支付',
              closeOnClickOverlay:true
            }).then(() => {//已支付完成检测
              this.findOrderStatus()
            }).catch((res) => {
              if(res == 'overlay'){//关闭弹层
                this.clearTime()
              }else{//重新支付
                this.getCreateOrder(this.json)
              }
            })
            if(this.timer == null && this.count<this.maxcount){
              this.getTimerStatus()
            }
          }
        })
      },
      getTimerStatus(){
        this.timer = setInterval(() => {
          if(this.count>=this.maxcount){
            this.clearTime()
            return false
          }
          this.count=this.count+1
          this.findOrderStatus()
        },1*1000)
      },
      clearTime(){
        clearInterval(this.timer)
        sessionStorage.removeItem(this.sessionKey)
      },
      finishPay(){
        this.jumpUrl('https://api.youzuanmy.vip/xd/paysuccess.html?orderNo='+this.json.order_sn)
      },
      onSubmitPay(status){
        switch (status) {
          case 1:
            this.findOrderStatus()
            break;
          case 0:
            this.findOrderStatus()
            break;
        }
      },
      jumpUrl(url){
        this.isback = true
        setTimeout(()=>{
          location.href=url
        },300)
      },
      onImagePreview(imgs){
        ImagePreview({
          images:imgs,
          closeable: true,
        })
      }
    }
  }
</script>
<style lang="less" scoped>
  @import "./css/index2.less";
</style>
